'use client';

import PlatformDynamicComponents from '@/components/core/PlatformDynamic';
import { AppSupport, Footer } from '@/app/_components/platform';
import { FaqBanner, Faqs, Zendesk } from '@/app/components/shared';

import Header from '@/components/platform/Header';

import { ClientProvider } from '@/contexts/client.context';

import { products } from '@/utils/products';

const MainPage = ({ content }) => {
  const { body, app, bottomCTA, disclaimer, footnotes, faqs, contact, header } = content;

  const faqBanner = {
    title: 'Still have questions? Get in touch.',
  };

  return (
    <>
      <ClientProvider
        defaultClient={{
          clientKey: null,
          onboardingUrl: '',
          institutionKey: null,
          launchDate: null,
          isActive: true,
          product: products.PLATFORM,
        }}
      >
        <Header content={header} />
        <PlatformDynamicComponents body={body} />
        {faqs && <Faqs content={faqs} />}
        <FaqBanner contact={contact} content={faqBanner} />
        {app && <AppSupport content={app} />}
        <Footer
          bottomCTA={bottomCTA}
          disclaimer={disclaimer}
          footnotes={footnotes}
          scrollTop
          email={contact?.email}
        />
      </ClientProvider>

      <Zendesk department={content?.zendeskDepartment} />
    </>
  );
};

export default MainPage;
